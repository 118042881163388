import * as React from 'react';
import LogoIcon from './LogoIcon';

export interface LogoProps {
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
}

const Logo = (props: LogoProps) => {
  const { width, height, fill, className } = props;

  return (
    <LogoIcon
      fill={fill}
      width={width}
      height={height}
      className={className}
      aria-hidden={'true'}
    />
  );
};

export default Logo;
